<template>
  <v-container
    fluid
    :class="{
      'title-item-dark': $vuetify.theme.dark,
    }"
  >
    <carregando v-if="carregandoSalvar" />
    <v-card-actions>
      <v-row class="mt-1">
        <v-col>
          <btn-voltar to="/usuarios" />
        </v-col>
      </v-row>
    </v-card-actions>
    <usuarios-campos
      :validacao-formulario="validacaoFormulario"
      @salvar="salvar"
    />
  </v-container>
</template>

<script>
import usuarios from "@/api/usuarios";

export default {
  components: {
    UsuariosCampos: () => import("./UsuariosCampos"),
  },

  data() {
    return {
      carregandoSalvar: false,
      validacaoFormulario: {},
    };
  },

  methods: {
    async salvar(usuario) {
      this.carregandoSalvar = true;
      this.validacaoFormulario = {};

      try {
        await usuarios.inserir(usuario);

        this.$router.push("/usuarios", () =>
          this.$snackbar.mostrar({
            cor: "success",
            mensagem: this.$mensagens._("sucesso_adicionar"),
          })
        );
      } catch (e) {
        if (e.response.status === 422) {
          this.validacaoFormulario = this.$erroApi.validacao(e);
          return;
        }

        this.$snackbar.mostrar({ cor: "error", mensagem: this.$erroApi._(e) });
      } finally {
        this.carregandoSalvar = false;
      }
    },
  },
};
</script>
import axios from '@/plugins/axios'

export default {

  async buscar (id) {
    return await axios.get(`usuarios/${id}`)
  },

  async inserir (dados) {
    return await axios.post('usuarios', dados)
  },

  async atualizar (id, dados) {
    return await axios.put(`usuarios/${id}`, dados)
  },

  async deletar (id) {
    return await axios.delete(`usuarios/${id}`)
  },

  async alterarSenha (id, dados) {
    return await axios.patch(`usuarios/${id}/alterar-senha`, dados)
  },

  async buscarGrupos (id) {
    return await axios.get(`usuarios/${id}/grupos`)
  },

  async atualizarGrupos (id, dados) {
    return await axios.put(`usuarios/${id}/grupos`, dados)
  },


}
